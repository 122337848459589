import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Collapsible, Text, Heading } from 'grommet';
import { Up, Down } from 'grommet-icons';

/* Box displaying a description text of CodeSearch on the main page
 * Can be collapsed by pressing the arow or programmatically
 */
class InfoBox extends Component {

	state = {
		collapsed: false
	}

	setCollapsed = (collapsed) => {
		this.setState({collapsed: collapsed});
	}

	render() {
		const { collapsed } = this.state;
		const { size } = this.props;
		return (
			<Box margin="xsmall" width={size === 'small' ? 'auto' : '50%'} gap="medium" align="center" >
				<Collapsible direction="vertical" open={!collapsed}>
					<Box gap="medium">
						<Text textAlign="center">
							<Heading level="2" style={{'marginBlockStart': '0', 'marginBlockEnd': '0'}}>Welcome to CodeSearch</Heading>
						</Text>
						<Text textAlign="center">
							Easily search across multiple internal code and information systems like <a href='https://github.platforms.engineering'>GitHub</a>, <a href='https://gitlab.bayer.com'>GitLab</a>, and documentation pages (e.g. <a href='https://docs.cloud.bayer.com'>go/cloud</a>). 
							
							Using CodeSearch, you get an overview of our existing resources, enabling a <a href='https://www.go-fair.org/fair-principles/'>FAIR</a> approach. 
						</Text>
						<Text textAlign="center">
							Search with a term and the scope. You will be provided with a matching list of assets, which link to the coresponding resource page. 
							It is not guranteed, that you have the permissions to access linked resources. You can view members of a resource through the &apos;contact&apos; button on the top right of a result.
							<br/>
						</Text>
						<Text textAlign="center">
							This application is work in progress. Feel free to report issues or add feature requests to the <a href='https://gitlab.bayer.com/codesearch/codesearch/-/issues'>Issue Board in GitLab</a> or <a href="mailto:tim.meinerzhagen@bayer.com?subject=CodeSearch">get in contact</a> directly.
						</Text>
					</Box>
				</Collapsible>
				<Box direction="row" align="center" gap="medium" data-testid="collapseIcon" onClick={() => this.setState({collapsed: !collapsed})}>
					{collapsed ? <Down /> : <Up />}
				</Box>
			</Box>
		);
	}
}

InfoBox.propTypes = {
	size: PropTypes.string
};

export { InfoBox };