import React, { Component } from 'react';
import { Button, Box, Layer, Text} from 'grommet';

import config from '../../config';

class ErrorLayer extends Component {
	
	state = {
		active: false,
		text: '',
		showMail: false
	}

	setError = (text, showMail) => {
		this.setState({
			active: true,
			text: text,
			showMail: showMail
		});
	};

	render(){
		const { active, text, showMail } = this.state;

		return (
			<Box>
				{active && (
					<Layer position="center" onClickOutside={() => this.setState({active: false})} onEsc={() => this.setState({active: false})}>
						<Box pad="medium" gap="large" width="auto" align="center">
							<Text>{text}</Text>
							<Box direction="row" alignSelf="end" gap="medium">
								{/* "Mailto" button only appears on API issues */}
								{ showMail && (
									<Button
										label={
											<Text color="accent-4">
											Email Us
											</Text>
										}
										href={`mailto:${config.contact.CONTACT_MAIL}?subject=CodeSearch`}
										color="accent-4"
									/>
								)}
								<Button
									label={
										<Text color="white">
											<strong>OK</strong>
										</Text>
									}
									onClick={() => this.setState({active: false})}
									primary
									color="accent-4"
								/>
							</Box>
						</Box>
					</Layer>
				)}
			</Box>
		);
	}
}

export { ErrorLayer };