import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardBody, Text, Box } from 'grommet';
import { StatusGood } from 'grommet-icons';

//import { categoryMapping } from '../../helper';

import { ContactButton } from '../';


class GenericCard extends Component {

	state = {
		isFeatured: false
	}

	renderCardContent() { return; }
	getTitle() { return; }
	
	render(){
		const { isFeatured } = this.state;
		const { category, kind, data, token, errorLayer } = this.props;
		//const categoryData = categoryMapping[category];
		return (
			<Card 
				height="auto" width="100%"
				border={{color: (isFeatured ? 'accent-4' : 'light-4'), style:'solid', size: 'medium'}} 
			>
				<CardHeader pad={{horizontal: 'small', top:'small', bottom: 'xsmall'}} background={(isFeatured ? 'accent-4' : 'light-4')}>
					<Box direction="column">
						{isFeatured && (
							<Text size="small"><StatusGood size="small" /> CodeSearch Ready</Text> 
						)}
						{this.getTitle()}
					</Box>
					{(kind !== 'docs') && 
						<ContactButton category={category} kind={kind} data={data} token={token} errorLayer={errorLayer} />
					}
				</CardHeader>
				
				<CardBody pad="small">
					{this.renderCardContent()}
				</CardBody>
				
				{/*<CardFooter 
					background={(isFeatured ? 'accent-4' : 'light-4')}
					//pad={{ horizontal: 'medium', vertical: 'small' }}
				>
					<Text size="small">
						{getIcon(categoryData.icon, 'small')}
						&nbsp;
						{categoryData.title}
					</Text> 
					<Avatar src={`${kind}.png`} size="small" round={false}/>
				</CardFooter>*/}
			</Card>
		);
	}
}

GenericCard.propTypes = {
	category: PropTypes.string,
	kind: PropTypes.string,
	data: PropTypes.object,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { GenericCard };