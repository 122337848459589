import React, { Component } from 'react';

import { Box, Text } from 'grommet';

import config from '../config';

import { getParams, AWS } from '../helper';

class Login extends Component {

	processAuth = async () => {
		const params = getParams(window.location.search);
		const type = window.location.pathname.split('/')[2];

		const response = await AWS.call('search', '/auth', {
			provider: type,
			code: params.code,
			redirect_url: config.provider[type].REDIRECT_URL
		});
		const token = JSON.parse(response.token);
		if(token.access_token != undefined)
			localStorage.setItem('token_' + type, token.access_token);
		window.location = '/';
	}

	render() {
		this.processAuth();
		return (
			<Box fill justify="center" align="center">
				<Text>Loading...</Text>
			</Box>
		);
	}
}

export { Login };
