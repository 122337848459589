import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'grommet';
import { Network, Star } from 'grommet-icons';

import { GenericCard } from './GenericCard';
import { updatedAgo } from '../../helper/functions';

class ProjectCard extends GenericCard {

	state = {
		web_url: '',
		title: '',
		description: '',
		star_count: '',
		forks_count: '',
		last_activity_at: '',
		owner_name: '',
		owner_url: '',
		tags: []
	}

	constructor(props) {
		super(props);

		this.state = this.loadData();
	}

	loadData() {
		const { kind, data } = this.props;

		let isFeatured = false;
		if(this.props.kind === 'gitlab')
			isFeatured = data.tag_list.includes('CodeSearch-Ready');
		
		let result;
		if(kind === 'github')
			result = { 
				isFeatured: isFeatured,
				web_url: data.svn_url,
				title: data.full_name ,
				description: data.description === '' ? 'No description' : data.description ,
				star_count: data.stargazers_count,
				forks_count: data.forks_count,
				last_activity_at: data.updated_at,
				owner_name: data.owner.login,
				owner_url: data.owner.html_url,
				tags: []//data.topics
			};
		else
			result =  { 
				isFeatured: isFeatured,
				web_url: data.web_url,
				title: data.name_with_namespace,
				description: data.description === '' ? 'No description' : data.description,
				star_count: data.star_count,
				forks_count: data.forks_count,
				last_activity_at: data.last_activity_at,
				owner_name: data.name_with_namespace.split(' / ')[0],
				owner_url: `https://gitlab.bayer.com/${data.web_url.split('/')[3]}`,
				tags: data.tag_list
			};
		return result;
	}

	getTitle() {
		const { web_url, title } = this.state;
		return (
			<Box direction="row" pad="xsmall" gap="xxsmall" justify="between" style={{ margin: '0px', padding: '0px'}}>
				<a style={{ textDecoration:'none'}} href={web_url} target="_blank" rel="noreferrer">
					<Text level="5" color="dark-1">
						{title.split('/').map(word => word.trim()).slice(0,-1).join(' / ') + ' / '}
					</Text>
					<Text level="5" color="dark-1" weight={500}>
						{title.split('/').slice(-1)[0].trim()}
					</Text>
				</a>
				
			</Box>
			
		);
	}
	
	renderCardContent(){
		const { description, star_count, forks_count, last_activity_at, owner_name, owner_url, tags } = this.state;
		return (
			<Box>			
				<Box direction="row" justify="between" align="center">
					<Text size="small" color="dark-3">by <a style={{ textDecoration:'none'}} href={owner_url} target="_blank" rel="noreferrer">{owner_name}</a><br/> Last updated {updatedAgo(last_activity_at)}</Text>
					<Box direction="row" pad="xsmall" gap="xxsmall">
						<Text size="medium" color={star_count === 0 ? 'dark-3' : 'dark-1'}>{star_count}</Text>
						<Star size="21px" color={star_count === 0 ? 'dark-3' : 'accent-4'}/>
						<Text size="medium" color={forks_count === 0 ? 'dark-3' : 'dark-1'}>{forks_count}</Text>
						<Network size="21px" color={forks_count === 0 ? 'dark-3' : 'accent-4'}/>
					</Box>
				</Box>
				<br/>
				<Text size="small" color="dark-3">{description}</Text>
				<Box direction="row" gap="xsmall" wrap>{tags.map((item, index) => <Box background="accent-4" pad="xxsmall" margin="xxsmall" round={true} key={index}><Text size="small">{item}</Text></Box>)}</Box>
			</Box>
		);
	}
}

ProjectCard.propTypes = {
	category: PropTypes.string,
	kind: PropTypes.string,
	data: PropTypes.object,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { ProjectCard };