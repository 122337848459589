import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, Box, Text, Footer } from 'grommet';
import { MailOption, Bug, Install, CircleAlert } from 'grommet-icons';

import config from '../../config';

class PageFooter extends Component {
	render(){
		const { size } = this.props;

		return (
			<Footer background={{color: 'footer-main'}} pad="small" justify="between" width="100%" direction={size === 'small' ? 'column' : 'row'}>
				<Box
					direction='row'
					gap="small"
				>
					<Link to="/" style={{ textDecoration: 'none', color: '#000' }}>CodeSearch</Link>
					<Link to="/privacy" style={{ textDecoration: 'none', color: '#000' }}>Privacy</Link>
				</Box>
				<Box
					direction='row'
					gap="small"
					align="center"
				>
					<Text size="small" align="center">By Technical Platforms​ - Integration Hub​</Text>
					<Button href={`mailto:${config.contact.BUG_REPORT_MAIL}?subject=Enter Bug Title&body=${escape(config.contact.BUG_REPORT_BODY)}`} size="small" icon={<Bug />} label="Report Bug"/>
					<Button href="https://gitlab.bayer.com/codesearch/codesearch/-/issues" size="small" icon={<CircleAlert />} label="Issue Board"/>
					<Button href="https://gitlab.bayer.com/codesearch/codesearch" size="small" icon={<Install />} label="Project Page"/>
					<Button href={`mailto:${config.contact.CONTACT_MAIL}?subject=CodeSearch`} size="small" icon={<MailOption />} label="Contact"/>
				</Box>
			</Footer>
		);
	}
}

PageFooter.propTypes = {
	size: PropTypes.string
};

export { PageFooter };