import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, CheckBoxGroup, Form, FormField, TextInput, Heading, Select, CheckBox, Card, CardFooter } from 'grommet';
import { Up, Down, Code, Catalog, Search } from 'grommet-icons';

/* The box where you enter the search parameters and click search
 * Actual search handeled in parent component
 */
class InputBox extends Component {

	state = {
		value: { query: '', 'checksGit': ['projects'], 'checksDocs': ['cloud'], 'sortBy': 'Relevant', 'sortOrderAsc': false },
		checksGit: ['projects'],
		checksDocs: ['cloud'],
		sortBy: 'Relevant',
		sortOrderAsc: false
	}

	render(){
		const { value, checksGit, checksDocs, sortBy, sortOrderAsc } = this.state;
		const { handleSearch } = this.props;

		return (
			<Card 
				flex={false} justify="center" align="center" margin="medium" round={true} 
				border={{color: 'accent-4', style:'solid', size: 'medium'}}
				elevation="none"
			>
				<Form
					value={value}
					onChange={nextValue => this.setState({ value: nextValue })}
				>
					<FormField name="query" htmlfor="text-input-id" 
						style={{'padding': '10px', 'marginBottom': '0'}}
					>
						<TextInput id="text-input-id" name="query" placeholder="What are you looking for?" autoFocus/>
					</FormField>
					<FormField name="git"
						style={{'paddingBottom': '0', 'marginBottom': '0'}}
					>
						<Box direction="row" gap="small">
							<Code/>
							<Heading level="4" align="center" style={{'marginBlockStart': '0', 'marginBlockEnd': '0'}}>Code Repositories</Heading>
						</Box>
						<CheckBoxGroup
							id="check-box-group-git"
							direction="row"
							name="checksGit"
							wrap={true}
							pad="small"
							gap="medium"
							margin={{left: 'medium'}}
							labelKey="label"
							valueKey="id"
							value={checksGit}
							onChange={({ checks: nextValue }) => {
								this.setState({ checksGit: nextValue });
							}}
							options={[
								{ label: 'Projects/Repositories', id: 'projects', 'data-testid': 'projects' },
								//{ label: 'Issues', id: 'issues', 'data-testid': 'issues' },
								//{ label: 'Pull/Merge Requests', id: 'requests', 'data-testid': 'requests' },
								{ label: 'Users', id: 'users', 'data-testid': 'users' },
								{ label: 'Code', id: 'code', 'data-testid': 'code'}
							]}
						/>
					</FormField>
					<FormField name="docs" style={{'marginBottom': '0'}} >
						<Box direction="row" margin="small" justify="between">
							<Box direction="column" >
								<Box direction="row" gap="small">
									<Catalog/>
									<Heading level="4" align="center" style={{'marginBlockStart': '0', 'marginBlockEnd': '0'}}>Documentation</Heading>
								</Box>
								<CheckBoxGroup
									id="check-box-group-docs"
									data-testid="checksDocs"
									direction="row"
									name="checksDocs"
									wrap={true}
									pad="small"
									gap="medium"
									margin={{left: 'medium'}}
									labelKey="label"
									valueKey="id"
									value={checksDocs}
									onChange={({ checks: nextValue }) => {
										this.setState({ checksDocs: nextValue });
									}}
									options={[
										{ label: 'go/cloud', id: 'cloud', 'data-testid': 'cloud' },
										{ label: 'Enterprise Data Hub', id: 'edh', 'data-testid': 'edh' },
										{ label: 'Data@Scale', id: 'atscale', 'data-testid': 'atscale' }
									]}
								/>
							</Box>
							<Box direction="row" justify="end" gap="medium" style={{display: 'None'}}>
								<CheckBox
									name="sortOrderAsc"
									data-testid="sortOrderAsc"
									checked={sortOrderAsc}
									label={sortOrderAsc ? <Up/> : <Down/>} 
									onChange={() => this.setState({ sortOrderAsc: !sortOrderAsc })}
									toggle={true}
								/>
								<Select 
									name="sortBy"
									data-testid="sortBy"
									size="medium"
									options={['Relevant', 'Name', 'Created At', 'Last activity', 'Top']}
									value={sortBy}
									onChange={({ option }) => this.setState({ sortBy: option })}
								/>
								
							</Box>
						</Box>
					</FormField>
					<CardFooter 
						direction="row" justify="center"
						background="accent-4"
						elevation="small"
					>
						<Button margin="xxsmall" type="submit" active={false} onClick={() => {handleSearch(value);}} label={
							<Box direction="row" align="center" gap="small" pad={{left: 'xxxsmall', right: 'xxxsmall'}}>
								<Search />
								Search
							</Box>
						}/>
					</CardFooter>
				</Form>
			</Card>
		);
	}
}

InputBox.propTypes = {
	handleSearch: PropTypes.func
};

export { InputBox };