const dev = {
	cognito: {
		USER_POOL_ID: 'eu-central-1_Ho9V9Ea2V',
		APP_CLIENT_ID: '30bj12stg8srjugo0mnm5c6gb7',
		OAUTH: {
			DOMAIN: 'login.codesearch-staging.cloud.bayer.com',
			REDIRECT_SIGNIN: 'http://localhost:3000',
			REDIRECT_SIGNOUT: 'http://localhost:3000',
		}
	},
	provider: {
		github: {
			REDIRECT_URL: 'http://localhost:3000/oauth/github'

		},
		gitlab: {
			REDIRECT_URL: 'http://localhost:3000/oauth/gitlab'
		}
	},
	apiGateway: {
		URL: 'http://localhost:3050/test'
	}
};

const staging = {
	cognito: {
		USER_POOL_ID: 'eu-central-1_0BI7VmzAc',
		APP_CLIENT_ID: '30bj12stg8srjugo0mnm5c6gb7',
		OAUTH: {
			DOMAIN: 'login.codesearch-staging.cloud.bayer.com',
			REDIRECT_SIGNIN: 'https://codesearch-staging.cloud.bayer.com',
			REDIRECT_SIGNOUT: 'https://codesearch-staging.cloud.bayer.com'
		}
	},
	provider: {
		github: {
			REDIRECT_URL: 'https://codesearch-staging.cloud.bayer.com/oauth/github'

		},
		gitlab: {
			REDIRECT_URL: 'https://codesearch-staging.cloud.bayer.com/oauth/github'
		}
	},
	apiGateway: {
		URL: 'https://api.codesearch-staging.cloud.bayer.com'
	}
};

const prod = {
	cognito: {
		USER_POOL_ID: 'eu-central-1_XvFtx3YjI',
		APP_CLIENT_ID: '51390pjt4tfav9k2e5fu1qc9as',
		OAUTH: {
			DOMAIN: 'login.codesearch.cloud.bayer.com',
			REDIRECT_SIGNIN: 'https://codesearch.cloud.bayer.com',
			REDIRECT_SIGNOUT: 'https://codesearch.cloud.bayer.com'
		}
	},
	provider: {
		github: {
			REDIRECT_URL: 'https://codesearch.cloud.bayer.com/oauth/github'

		},
		gitlab: {
			REDIRECT_URL: 'https://codesearch.cloud.bayer.com/oauth/github'
		}
	},
	apiGateway: {
		URL: 'https://api.codesearch.cloud.bayer.com'
	}
};

const config = process.env.REACT_APP_STAGE === 'prod'   ? prod : (process.env.REACT_APP_STAGE === 'staging' ? staging : dev);

export default {
	cognito: {
		REGION: 'eu-central-1',
		USER_POOL_ID: config.cognito.USER_POOL_ID,
		APP_CLIENT_ID: config.cognito.APP_CLIENT_ID,
		OAUTH: {
			DOMAIN: config.cognito.OAUTH.DOMAIN,
			SCOPE: ['email', 'openid', 'profile'],
			REDIRECT_SIGNIN: config.cognito.OAUTH.REDIRECT_SIGNIN,
			REDIRECT_SIGNOUT: config.cognito.OAUTH.REDIRECT_SIGNOUT,
			RESPONSE_TYPE: 'token'
		}
	},
	provider: {
		github: {
			APP_CLIENT_ID: '1e2081f6828e5563743c',
			REDIRECT_URL: config.provider.github.REDIRECT_URL,
			ADDITIONAL_PARAMS: ''

		},
		gitlab: {
			APP_CLIENT_ID: '709a202e2a74f1db8ad9ff87892409ccc1c03529ea8c43b79c2d7d378e22686e',
			REDIRECT_URL: config.provider.gitlab.REDIRECT_URL,
			ADDITIONAL_PARAMS: '&response_type=code&scope=profile'
		}
	},
	apiGateway: {
		REGION: 'eu-central-1',
		URL: config.apiGateway.URL
	},
	googleanalytics: {
		GTM_ID: 'GTM-WC23ZP5'
	},
	contact: {
		CONTACT_MAIL: 'tim.meinerzhagen@bayer.com',
		BUG_REPORT_MAIL: 'incoming+codesearch-codesearch-8860-issue-@gitlab.bayer.com',
		BUG_REPORT_BODY: 'Problem to solve\n\nProposal Further\n\ndetails (optional)\n\nDocumentation / Links (optional)'
	}
};
