import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Avatar, Header, Button, Heading, Box  } from 'grommet';
// import { Avatar, Header, Button, Heading, Box, Menu  } from 'grommet';
import { Logout } from 'grommet-icons';
// import { Sun, Moon } from 'grommet-icons';

import { AWS } from '../../helper';

// import config from '../../config';

const BayerLogo = () => {
	return(
		<Box background={{color: 'header-accent'}} width="150px" height="60px" margin={{'left': '-30px'}} align="center" style={{transform: 'skew(-20deg)'}}>
			<Avatar src='bayercross_white.png' size="50px" margin={{'left': '15px', 'top': '5px', 'bottom': '5px'}}  round={true} style={{transform: 'skew(20deg)'}} align="center" />
		</Box>
	);
};

class PageHeader extends Component {

	state = {
		github_load: localStorage.getItem('token_github') == undefined,
		gitlab_load: localStorage.getItem('token_gitlab') == undefined,
		mode_light: localStorage.getItem('appearance_mode') == undefined || localStorage.getItem('appearance_mode') == 'light'
	}

	render(){
		// const { github_load, gitlab_load, mode_light } = this.state;
		// const { setAppearanceMode } = this.props;

		return (
			<Header gridarea="header" background={{color: 'header-main'}} >
				<Box direction='row' gap="large" align="center" justify="between">
					<BayerLogo />
					<Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
						<Heading level="2">CodeSearch</Heading>
					</Link>
				</Box>
				<Box direction='row' gap="medium" align="center" justify="between">
					{/* Menu
						label="Options"
						items={[
							{ 
								icon: <Box pad="small"><Avatar src={'github.png'} size="small" round={false} /></Box>,
								label: <Box alignSelf="center"> { github_load ? 'Connect GitHub' : 'Disconnect GitHub'} </Box>, 
								onClick: () => {
									if(github_load)
										window.location.href = `https://github.platforms.engineering/login/oauth/authorize?client_id=${config.provider.github.APP_CLIENT_ID}&redirect_uri=${config.provider.github.REDIRECT_URL}${config.provider.github.ADDITIONAL_PARAMS}`;
									else {
										localStorage.removeItem('token_github');
									}
									this.setState({
										github_load: !github_load,
										gitlab_load: gitlab_load,
										mode_light: mode_light
									});
								} 
							},
							{ 
								icon: <Box pad="small"><Avatar src={'gitlab.png'} size="small" round={false} /></Box>,
								label: <Box alignSelf="center"> { gitlab_load ? 'Connect GitLab' : 'Disconnect GitLab'} </Box>, 
								onClick: () => {
									if(gitlab_load)
										window.location.href = `https://gitlab.bayer.com/oauth/authorize?client_id=${config.provider.gitlab.APP_CLIENT_ID}&redirect_uri=${config.provider.gitlab.REDIRECT_URL}${config.provider.gitlab.ADDITIONAL_PARAMS}`;
									else {
										localStorage.removeItem('token_gitlab');
									}
									this.setState({
										github_load: github_load,
										gitlab_load: !gitlab_load,
										mode_light: mode_light
									});
								} 
							},
							{ 
								icon: <Box pad="small">{mode_light ? <Moon /> : <Sun />}</Box>,
								label: <Box alignSelf="center"> Switch to { mode_light ? 'Dark Mode' : 'Light Mode'} </Box>, 
								onClick: () => {
									if(mode_light) {
										setAppearanceMode('dark');
										localStorage.setItem('appearance_mode', 'dark');
									} else {
										setAppearanceMode('light');
										localStorage.setItem('appearance_mode', 'light');
									}
									this.setState({
										github_load: github_load,
										gitlab_load: gitlab_load,
										mode_light: !mode_light
									});
								} 
							},
						]}
					/> */}
					<Button icon={<Logout />} reverse={true} label="Logout" onClick={ () => { AWS.handleLogout(); }} margin={{'right': '10px'	}} />
				</Box>
			</Header>
		);
	}
}

PageHeader.propTypes = {
	setAppearanceMode: PropTypes.func
};

export { PageHeader };