import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import { InfoBox, InputBox, ResultTabs } from '../components/box';
import { AWS, GoogleTagManager, Spinner } from '../helper';

class App extends Component {

	state = {
		query: '',
		searchResults: {},
		loadedResults: [],
		isLoading: false,
		infoBoxCollapsed: false
	}

	constructor(props){
		super(props);
		this.infoBox = React.createRef();
		this.resultTabs = React.createRef();
	}

	handleSearch = async (value) => {
		this.setState({search: value.query});

		// Error handling for empty inputs
		if (value.query === '') {
			this.props.errorLayer.current.setError('Please enter a search value!', false);
			return; 
		}
		if (value.checksGit.length === 0 && value.checksDocs.length === 0) {
			this.props.errorLayer.current.setError('Please select at least one search category!', false);
			return; 
		}

		this.infoBox.current.setCollapsed(true);

		GoogleTagManager.event('Search', [value.checksGit.join(';'), value.checksDocs.join(';')].join(';'), value.query);

		// Build scope info
		const scopes = value.checksGit.concat(value.checksDocs);
		this.setState({
			loadedResults: scopes,
			isLoading: true
		});

		const sortOrder = value.sortOrderAsc ? 'asc' : 'desc';

		let results = {};
		try {
			results = await AWS.call('search', '/search', {
				systems: (value.checksGit.length > 0 ? ['github', 'gitlab'] : []).concat((value.checksDocs.length > 0 ? ['docs'] : [])).join(';'),
				scopes: scopes.join(';'),
				query: value.query,
				sortBy: value.sortBy,
				sortOrder: sortOrder
			});
		} catch (error) {
			this.props.errorLayer.current.setError('Uh, something went horribly wrong!', true);
			this.setState({isLoading: false});
		}

		this.setState({
			loadedGit: value.checksGit,
			loadedDocs: value.checksDocs,
			searchResults: results,
			isLoading: false
		});

		await new Promise(resolve => setTimeout(resolve, 50));
		document.getElementById('results').scrollIntoView({behavior: 'smooth'});
		this.resultTabs.current.setTabIndex(0);
	}

	render(){
		const { searchResults, loadedResults, isLoading, query } = this.state;
		const { size, token, errorLayer } = this.props;
		return (
			<Box gridarea="main" align="center">
				<InfoBox size={size} ref={this.infoBox} />
				<InputBox handleSearch={this.handleSearch} />
				<Box
					flex="grow"
					style={{maxWidth: '1240px', 'marginBottom': '0'}}
					margin={{bottom: 'large'}}
					width="100%"
					id="results"
				>
					{/* Every search category has its own tab to distinguish among results */}
					{(!isLoading && (searchResults)) && (
						<ResultTabs ref={this.resultTabs}
							searchResults={searchResults} loadedResults={loadedResults}
							query={query} size={size} token={token} errorLayer={errorLayer}
						/>
					)}
					{isLoading && (
						<Spinner />
					)}
				</Box>
			</Box>
		);
	}
}

App.propTypes = {
	size: PropTypes.string,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { App };
