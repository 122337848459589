import React, { Component } from 'react';
import { Box, Button, Heading, Text } from 'grommet';
import PropTypes from 'prop-types';
import { updatedAgo, limitChars } from '../../helper/functions';
import { ContactButton } from '../';

class IssueCard extends Component {

	state = {
		web_url: this.props.kind === 'github' ? this.props.data.html_url : this.props.data.web_url,
		number: this.props.kind === 'github' ? this.props.data.number : this.props.data.iid,
		title: this.props.data.title,
		description: this.props.kind === 'github' ? limitChars(this.props.data.body, 340) : limitChars(this.props.data.description, 340),
		state: this.props.data.state,
		author_name: this.props.kind === 'github' ? this.props.data.user.login : this.props.data.author.name,
		author_web_url: this.props.kind === 'github' ? this.props.data.user.html_url : this.props.data.author.web_url,
		created_at: this.props.data.created_at,
		repo: this.props.kind === 'github' ? (
			`${this.props.data.html_url.split('.engineering/')[1].split('/issues')[0].split('/').join(' / ')} #${this.props.data.number}`
		) : (
			`${this.props.data.web_url.split('.com/')[1].split('/-/')[0].split('/').join(' / ')} #${this.props.data.iid}`
		),
	}
  
	render(){
		const { web_url, title, description, state, author_name, author_web_url, created_at, repo } = this.state;
		const { kind, category, data, token, errorLayer } = this.props;
		return (
			<Box 
				width="100%" 
				height="auto" 
				border={{color: 'accent-4', style:'solid', size: 'medium'}} 
				pad={{horizontal: 'small', top:'small', bottom: 'xsmall'}}
				direction="column" 
				round={true}
			>
				<Box justify="between" direction='row' align="center">
					<Box direction='row' align="center">
						<a style={{ textDecoration:'none'}} href={web_url} target="_blank" rel="noreferrer">
							<Button background="dark-4" margin="small" size="small" color={state === 'closed' ? 'warning' : 'red'} label={state} />
						</a>
						<a style={{ textDecoration:'none'}} href={web_url} target="_blank" rel="noreferrer">
							<Heading margin="xsmall" level="5" color="dark-1">{title}</Heading>
						</a>
					</Box>
					<ContactButton category={category} kind={kind} data={data} token={token} errorLayer={errorLayer} />
				</Box>
				<Box width="auto" flex={true} pad={{horizontal: 'small', bottom: 'small'}}>
					<Text  size="small" color="dark-3">{repo}</Text>
					<Text  size="small" color="dark-3">
						Opened {updatedAgo(created_at)} by {' '}
						<a style={{ textDecoration:'none'}} href={author_web_url} target="_blank" rel="noreferrer">{author_name}</a>
					</Text>
					<Text size="small" margin={{top: 'small'}} color="dark-1">
						{description} 
					</Text>
				</Box>
			</Box>
		);
	}
}
	
IssueCard.propTypes = {
	category: PropTypes.string,
	kind: PropTypes.string,
	data: PropTypes.object,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { IssueCard };