import React from 'react';
import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';
import { GenericCard } from './GenericCard';

class CodeCard extends GenericCard {

	state = {
		path: '',
		web_url: '',
		repository: '',
		repo_url: ''
	}

	constructor(props) {
		super(props);

		this.state = this.loadData();
	}

	loadData() {
		const { kind, data } = this.props;

		let result;
		if(kind === 'github')
			result = { 
				isFeatured: false,
				path: data.path,
				web_url: data.html_url,
				repository: data.repository.full_name,
				repo_url: data.repository.html_url
			};
		else
			result = { 
				isFeatured: false,
				path: data.path,
				web_url: `/open?scope=projects&id=${data.project_id}&filename=${data.filename}&startline=${data.startline}`,
				repository: '',
				repo_url: ''
			};
		return result;
	}

	getTitle() {
		const { path, web_url } = this.state;
		return (
			<a style={{ textDecoration:'none'}} href={web_url} target="_blank" rel="noreferrer">
				<Text level="5" color="dark-1">
					{path.split('/').map(word => word.trim()).slice(0,-1).join(' / ') + ' / '}
				</Text>
				<Text level="5" color="dark-1" weight="bold">
					{path.split('/').slice(-1)[0].trim()}
				</Text>
			</a>
			
		);
	}
	
	renderCardContent(){
		const { repository, repo_url } = this.state;
		return (
			<Box width="auto" flex={true} pad="small">
				<a style={{ textDecoration:'none'}} href={repo_url} target="_blank" rel="noreferrer">
					<Text size="small" color="dark-3" margin={{top: 'xsmall'}}>{repository}</Text>
				</a>
			</Box>
		);
	}
}
	
CodeCard.propTypes = {
	category: PropTypes.string,
	kind: PropTypes.string,
	data: PropTypes.object,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { CodeCard };