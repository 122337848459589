/**
 * Grommet Theming as described in https://theme-designer.grommet.io/Dashboard
 */
export const theme = {
	'name': 'my theme',
	'rounding': 4,
	'spacing': 24,
	'defaultMode': 'light',
	'global': {
		'font': {
			'family': 'Roboto',
			'size': '18px',
			'height': '20px'
		},
		'colors': {
			'brand': {
				'dark': '#626C66',
				'light': '#918B76'
			},
			'background': {
				'dark': '#111111',
				'light': '#FFFFFF'
			},
			'background-back': {
				'dark': '#111111',
				'light': '#EEEEEE'
			},
			'background-front': {
				'dark': '#222222',
				'light': '#FFFFFF'
			},
			'background-contrast': {
				'dark': '#FFFFFF11',
				'light': '#11111111'
			},
			'header-main': {
				'dark': 'accent-4',
				'light': 'accent-4'
			},
			'header-accent': {
				'dark': '#624963',
				'light': '#624963'
			},
			'footer-main': {
				'dark': 'accent-4',
				'light': 'accent-4'
			},
			'text': {
				'dark': '#EEEEEE',
				'light': '#333333'
			},
			'text-strong': {
				'dark': '#FFFFFF',
				'light': '#000000'
			},
			'text-weak': {
				'dark': '#CCCCCC',
				'light': '#444444'
			},
			'text-xweak': {
				'dark': '#999999',
				'light': '#666666'
			},
			'border': {
				'dark': '#444444',
				'light': '#CCCCCC'
			},
			'result-card': {
				'dark': 'dark-3',
				'light': 'light-3'
			},
			'control': 'brand',
			'active-background': 'background-contrast',
			'active-text': 'text-strong',
			'selected-background': 'brand',
			'selected-text': 'text-strong',
			'status-critical': '#FF4040',
			'status-warning': '#FFAA15',
			'status-ok': '#00C781',
			'status-unknown': '#CCCCCC',
			'status-disabled': '#CCCCCC',
			'graph-0': 'brand',
			'graph-1': 'status-warning'
		},
		'active': {
			'background': 'active-background',
			'color': 'active-text'
		},
		'hover': {
			'background': 'active-background',
			'color': 'active-text'
		},
		'selected': {
			'background': 'selected-background',
			'color': 'selected-text'
		}
	},
	'chart': {},
	'diagram': {
		'line': {}
	},
	'meter': {},
	'layer': {
		'background': {
			'dark': '#111111',
			'light': '#FFFFFF'
		}
	},
	'checkBox': {
		'toggle': {
			'extend': {
				'display': 'none'
			}
		}
	},
	'card': {
		'container': {
			'elevation': 'xlarge'
		},
		'footer': {
			'pad': { 'horizontal': 'small', 'vertical': 'xsmall' }
		},
	},
};