import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

import config from '../config';

class GoogleTagManager {

	static initialize = () => {
		if(Cookies.get('codesearch_gtm') !== 'false'){
			Cookies.set('codesearch_gtm', 'true');
			const tagManagerArgs = {
				gtmId: config.googleanalytics.GTM_ID
			};				
			TagManager.initialize(tagManagerArgs);
		}
	}

	static event = (event, action, label) => {
		if(this.hasAcceptedCookie()){
			TagManager.dataLayer({
				gtmId:  config.googleanalytics.GTM_ID,
				dataLayer: {
					'event' : 'gaGenericEvent',
					'eventCategory': event,
					'eventAction': action,
					'eventLabel' : label
				}
			});
		}
	}

	static hasAcceptedCookie = () => {
		return Cookies.get('codesearch_gtm') === 'true';
	}

	static removeCookieConsent = () => {
		Cookies.set('codesearch_gtm', 'false');
		Cookies.remove('_ga');
		Cookies.remove('_gat');
		Cookies.remove('_gid');
	}

	static giveCookieConsent = () => {
		Cookies.set('codesearch_gtm', 'true');
		this.initialize();
	}
}

export { GoogleTagManager };