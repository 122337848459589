import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Avatar, Box, Collapsible, Text, Heading } from 'grommet';
import { Down, Next } from 'grommet-icons';

import { ProjectCard, IssueCard, DocsCard, RequestCard, UserCard, CodeCard } from '../cards/';

/* The list of results for a search for one type (e.g. GitHub, GitLab, DOcs)
 * Maps to specific Card depending on category
 */
class ResultBox extends Component {

	state = {
		collapsed: this.props.size === 'small'
	}

	render(){
		const { size, kind, category, search, data, token, errorLayer } = this.props;
		const { collapsed } = this.state;
		return (<>
			{(data && data.length > 0) ? (
				<Box margin="medium" width={size === 'small' || kind === 'docs' ? 'auto' : '50%'} gap="medium" align="center">
					<Box direction="row" align="center" gap="medium" onClick={() => this.setState({collapsed: !collapsed})} data-testid="collapseIcon">
						{collapsed ? <Next /> : <Down />}
						{kind === 'docs' &&
							<Heading level="4">Documentation</Heading>
						}
						{kind !== 'docs' &&
							<Avatar src={`${kind}.png`} round={false}/>
						}
					</Box>
					<Collapsible direction="vertical" open={!collapsed}>
						<Box gap="medium">
							{category === 'projects' && data.map((project, index) => <ProjectCard category={category} kind={kind} key={index} data={project} token={token} errorLayer={errorLayer}/>)}
							{category === 'issues' && data.map((issue, index) => <IssueCard category={category} kind={kind} key={index} data={issue} token={token} errorLayer={errorLayer}/>)}
							{category === 'requests' && data.map((request, index) => <RequestCard category={category} kind={kind} key={index} data={request} token={token} errorLayer={errorLayer}/>)}
							{category === 'users' && data.map((user, index) => <UserCard category={category} kind={kind} key={index} data={user} token={token} errorLayer={errorLayer}/>)}
							{category === 'code' && data.map((code, index) => <CodeCard category={category} kind={kind} key={index} data={code} token={token} errorLayer={errorLayer}/>)}
							
							{category === 'cloud' && data.map((docs, index) => <DocsCard category={category} kind={kind} search={search} key={index}  data={docs} token={token} errorLayer={errorLayer}/>)}
							{category === 'edh' && data.map((docs, index) => <DocsCard category={category} kind={kind} search={search} key={index}  data={docs} token={token} errorLayer={errorLayer}/>)}
							{category === 'atscale' && data.map((docs, index) => <DocsCard category={category} kind={kind} search={search} key={index}  data={docs} token={token} errorLayer={errorLayer}/>)}
						</Box>
					</Collapsible>
				</Box>
			) : (
				<Box margin="medium" width={size === 'small' ? 'auto' : '50%'} gap="medium" align="center">
					<Box direction="row" align="center" gap="medium">
						{kind === 'docs' &&
							<Heading level="4">go/cloud</Heading>
						}
						{kind !== 'docs' &&
							<Avatar src={`${kind}.png`} round={false}/>
						}
					</Box>
					<Text>No results found on {kind.replace(/^\w/, (c) => c.toUpperCase())}.</Text>
				</Box>
			)}
		</>
		);
	}
}

ResultBox.propTypes = {
	size: PropTypes.string,
	kind: PropTypes.string,
	category: PropTypes.string,
	search: PropTypes.string,
	data: PropTypes.array,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { ResultBox };