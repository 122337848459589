import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Text, Heading, Button } from 'grommet';

import { GoogleTagManager } from '../helper';


class Privacy extends Component {
		
	state = {
		cookieConsent:  GoogleTagManager.hasAcceptedCookie() ? 'Given' : 'Not given'
	}

	changeCookieConsent = (e) => {
		e.preventDefault();
		if(GoogleTagManager.hasAcceptedCookie()){
			GoogleTagManager.removeCookieConsent();
			this.setState({ cookieConsent: 'Not given' });
		}
		else{
			GoogleTagManager.giveCookieConsent();
			GoogleTagManager.event('Privacy', 'Change', 'activate');
			this.setState({ cookieConsent: 'Given' });
		}
	}
	
	render(){
		const { cookieConsent } = this.state;
		return (
			<Box
				fill justify="center" 
				align="center"
			>
				<Text>
					<Heading level="1">Privacy Statement</Heading>

					This website (hereinafter the <Text weight='bold'>&quot;Website&quot;</Text>) is provided by <Text weight='bold'>Bayer Aktiengesellschaft</Text> (hereinafter <Text weight='bold'>&quot;us&quot;</Text> or <Text weight='bold'>&quot;we&quot;</Text>). For further information regarding the provider of the Website, please refer to our <a href='https://bayer.com/imprint'>imprint</a>.

					<Heading level="2">Handling of personal data</Heading>

					In the following we wish to provide you with information on how we handle your personal data when you use our Website. Unless otherwise indicated in the following chapters, the legal basis for the handling of your personal data results from the fact that such handling is required to make available the functionalities of the Website requested by you (Art. 6(1)(b) General Data Protection Regulation). 

					<Heading level="2">Using our Website</Heading>
					<Heading level="3">Accessing our Website</Heading>

					When you call up our Website, your browser will transfer certain information to our web server. This is done for technical reasons and required to make available to you the requested information. To facilitate your access to the Website, the following information are collected, briefly stored and used:
					
					<ul>
						<li>IP address</li>
						<li>date and time of access</li>
						<li>Time zone difference to Greenwich Mean Time (GMT)</li>
						<li>Content of request (specific site)</li>
						<li>Status of access/HTTP status code</li>
						<li>Transferred volume of data</li>
						<li>Website requesting access</li>
						<li>Browser, language settings, version of browser software operating system and surface</li>
					</ul>

					When you call up our Website, your browser will transfer certain information to our web server. This is done for technical reasons and required to make available to you the requested information. To facilitate your access to the Website, the following information are collected, briefly stored and used:

					<Heading level="3">Third party login services / single sign on</Heading>

					Subject to you providing your consent (Art. 6(1)(a) General Data Protection Regulation), we provide you with the possibility to register to our services by using the third party login service provider Azure Active Directory (hereinafter &quot;Login-Service Provider&quot;). In order to use this login service, you will be asked to login to Login-Service Provider&#39;s service using your respective login credentials. By doing so, your profile will be connected to our service, which means that Login-Service Provider receives the information that you use our service and we automatically receive the following information about you: <br/><br/>
					
					<ul>
						<li>The fact that your identity has been authenticated by Login-Service Provider </li>
						<li>Your public profile</li>
						<li>Contact data (incl. email address)</li>
					</ul>

					We will use this information only for the registration purpose and in order to identify you during your logins. In case Login-Service Provider transfers more information about you than we need for the login verification purpose, we immediately delete this information. For further information on how Login-Service Provider&#39;s service work and how the Login-Service Provider processes your personal data, please refer to Login-Service Provider&#39;s <a href='https://privacy.microsoft.com/en-GB/privacystatement'>Privacy Statement</a>. We delete your personal data in case you delete your user account.

					<Heading level="2">Setting of cookies</Heading>
					<Heading level="3">What are cookies?</Heading>

					This Website uses so-called &quot;cookies&quot;. Cookies are small text files that are stored in the memory of your terminal via your browser. They store certain information (e.g. your preferred language or site settings) which your browser may (depending on the lifespan of the cookie) retransmit to us upon your next visit to our Website.

					<Heading level="3">What cookies do we use?</Heading>

					We differentiate between two categories of cookies: (1) functional cookies, without which the functionality of our Website would be reduced, and (2) optional cookies used for e.g. website analysis and marketing purposes. The following tables contain a detailed description of the optional cookies we use:

					<Heading level="3">Optional Cookies</Heading>

					Name: Website analysis with Google
					<ul>
						<li>Purpose and Content: Website analysis with Google These cookies assign a randomly generated ID to your device, enabling us to recognize your device upon your next access. For details on website analysis with Google, please see the respective chapter below.</li>
						<li>Lifespan: 6 months </li>
						<li>Provider: Google Inc.</li>
						<li>Consent: {cookieConsent}</li>
					</ul>

					<Button size="small" label="Change Cookie Consent" onClick={this.changeCookieConsent}/>

					<Heading level="3">How to prevent the setting of cookies</Heading>

					Of course you may use our Website without any cookies being set. In your browser, you can at any time configure or completely deactivate the use of cookies. This may, however, lead to a restriction of the functions or have adverse effects on the user-friendliness of our Website. You may at any time object to the setting of optional cookies by using the respective objection option indicated in the table above.

					<Heading level="2">Website Analysis with Google</Heading>

					On our Website we use a web analysis service of Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (&quot;Google&quot;). <br/>

					Google will analyze your use of our Website on our behalf. To this purpose, we use the cookies described in more detail in the above table. The information collected by Google in connection with your use of our Website (e.g. the referring URL, our webpages visited by you, your browser type, your language settings, your operating system, your screen resolution) will be transmitted to a server of Google in the US, where it will be stored and analyzed. The respective results will then be made available to us in anonymized form. Your usage data will not be connected to your full IP address during this process. We have activated on our Website the IP anonymizing function offered by Google, which will delete the last 8 bits (type IPv4) or the last 80 bits (type IPv6) of your IP address after each data transfer to Google. Moreover, Google is <a href='https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI'>certified under the EU-US Privacy Shield</a>, which ensures that an adequate level of data protection is maintained with respect to the processing of personal data by Google in the US.<br/><br/>

					Moreover, by concluding specific agreements with Google we ensure that an adequate level of data protection is maintained with respect to the processing of personal data by Google in the US. 
					
					You may withdraw your consent to the use of web analysis at any time, either by downloading and installing the provided <a href='https://tools.google.com/dlpage/gaoptout?hl=en-GB'>Google Browser Plugin</a> or by administrating your consents in the above table, in which case an opt-out cookie will be placed. Both options will prevent the application of web analysis only as long as you use the browser on which you installed the plugin and do not delete the opt-out Cookie.<br/>

					<Heading level="2">Transfer of personal data for commissioned processing</Heading>

					For the processing of your personal data we will to some extent use specialized service contractors. Such service contractors are carefully selected and regularly monitored by us. Based on respective data processor agreements, they will only process personal data upon our instruction and strictly in accordance with our directives. 

					<Heading level="2">Processing of personal data outside the EU / the EEA</Heading>

					Your personal data will in part also be processed in countries outside the European Union (“EU”) or the European Economic Area (“EEA”), which may have a lower data protection level than European countries. In such cases, we will ensure that a sufficient level of protection is provided for your personal data, e.g. by concluding specific agreements with our contractual partners (copy available on request), or we will ask for your explicit consent to such processing. 

					<Heading level="2">Information regarding your rights</Heading>

					The following rights are in general available to you according to applicable data privacy laws:

					<ul>
						<li>Right of information about your personal data stored by us;</li>
						<li>Right to request the correction, deletion or restricted processing of your personal data; </li>
						<li>Right to object to a processing for reasons of our own legitimate interest, public interest, or profiling , unless we are able to proof that compelling, warranted reasons superseding your interests, rights and freedom exist, or that such processing is done for purposes of the assertion, exercise or defense of legal claims;</li>
						<li>Right to data portability;</li>
						<li>Right to file a complaint with a data protection authority;</li>
						<li>You may at any time with future effect withdraw your consent to the processing of your personal data. For further information please refer to the chapters above describing the processing of personal data based on your consent.</li>
					</ul>

					If you wish to exercise your rights, please address your request to the contact indicated below, under contact. <br/><br/>

					<Text weight='bold'>If you are a resident of California or Nevada in the United States, you may have additional rights regarding your personal data. More information may be found <a href='https://www.bayer.us/en/global-us-privacy-statement-addendum'>here</a>.</Text>

					<Heading level="2">Contact</Heading>

					For any questions you may have with respect to data privacy, please write an e-mail to <a href='mailto:data.privacy@bayer.com'>data.privacy@bayer.com</a> or contact our company data protection officer at the following address:

					<br/><br/>
					Group Data Protection Officer <br/>
					Bayer AG <br/>
					51368 Leverkusen

					<Heading level="2">Amendment of Privacy Statement</Heading>

					We may update our Privacy Statement from time to time. Updates of our Privacy Statement will be published on our Website. Any amendments become effective upon publication on our Website. We therefore recommend that you regularly visit the site to keep yourself informed on possible updates.<br/><br/>

					Last updated: <Text weight='bold'>22.01.2021</Text>.
				</Text>
			</Box>
		);
	}
}

Privacy.propTypes = {
	size: PropTypes.string
};

export { Privacy };
