import React from 'react';
import { Projects, Code, Alert, Bar, User, Catalog } from 'grommet-icons';

export const categoryMapping = {
	projects: {
		title: 'Projects/Repositories',
		icon: Projects
	},
	issues: {
		title: 'Issues',
		icon: Alert
	},
	requests: {
		title: 'Pull/Merge Requests',
		icon: Bar
	},
	users: {
		title: 'Users',
		icon: User
	},
	code: {
		title: 'Code',
		icon: Code
	},
	cloud: {
		title: 'go/cloud',
		icon: Catalog
	},
	edh: {
		title: 'Enterprise Data Hub',
		icon: Catalog
	},
	atscale: {
		title: 'Data@Scale',
		icon: Catalog
	}
};

export const getIcon = (icon, size) => {
	const SpecificIcon = icon;
	return <SpecificIcon size={size} />;
};