import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs, Box } from 'grommet';

import { ResultBox } from './ResultBox';

import { categoryMapping, getIcon } from '../../helper';



class ResultTabs extends Component {

	state = {
		tabIndex: 0,
	}

	setTabIndex = (index) => {
		this.setState({ tabIndex: index });
	}

	/**
	 * Function to identify the number of results per category
	 */
	countResults = (category) => {
		const { searchResults } = this.props;

		const countGitlab = searchResults['gitlab'] ? (searchResults['gitlab'][category] ? searchResults['gitlab'][category].length : 0) : 0;
		const countGithub = searchResults['github'] ? (searchResults['github'][category] ? searchResults['github'][category].length : 0) : 0;
		const countDocs   = searchResults['docs']   ? (searchResults['docs'][category]   ? searchResults['docs'][category].length : 0) : 0;

		const count = countGitlab + countGithub + countDocs;
		return `${count > 50 ? '50+' : count}`;
	}
	
	render(){
		const { searchResults, loadedResults, 
			query, size, token, errorLayer } = this.props;
		const { tabIndex } = this.state;

		return (
			<Tabs alignControls="center" margin="small" activeIndex={tabIndex} onActive={(nextIndex) => this.setState({tabIndex: nextIndex})}>
				{/* GitHub / GitLab result tabs */}
				{loadedResults.map((category, index) => 
					<Tab title={`${categoryMapping[category].title} (${this.countResults(category)})`}
						icon={getIcon(categoryMapping[category].icon, 'medium')} 
						active={true} key={index}
					>
						{!['cloud', 'edh', 'atscale'].includes(category) &&
							<Box direction={size === 'small' ? 'column': 'row'}>
								<ResultBox 
									kind="gitlab" category={category}
									data={searchResults['gitlab'][category]} 
									size={size} token={token} errorLayer={errorLayer}/>
								<ResultBox 
									kind="github" category={category}
									data={searchResults['github'][category]
										? searchResults['github'][category] : []} 
									size={size} token={token} errorLayer={errorLayer}/>
							</Box>
						}
						{['cloud', 'edh', 'atscale'].includes(category) &&
							<Box direction={size === 'small' ? 'column': 'row'}>
								<ResultBox 
									kind="docs" category={category}
									data={searchResults['docs'][category]}  query={query}
									size={size}  token={token} errorLayer={errorLayer}/>
							</Box>
						}
					</Tab>
				)}
			</Tabs>
		);
	}
}

ResultTabs.propTypes = {
	query: PropTypes.string,
	searchResults: PropTypes.object,
	loadedResults: PropTypes.array,
	size: PropTypes.string,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { ResultTabs };