import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'grommet';

import { AWS, buildRedirect, getParams } from '../helper';

class OpenPage extends Component {

	redirect = async () => {
		const params = getParams(window.location.search);
		const redirectUrl = buildRedirect(await AWS.call('search', '/redirect/gitlab', { data: params }), params);
		window.location.href = redirectUrl;
	}

	render(){
		if(this.props.token !== '')
			this.redirect();
		
		return (
			<Box fill justify="center" align="center">
				<Text>Redirecting...</Text>
			</Box>
		);
	}
}

OpenPage.propTypes = {
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { OpenPage };
