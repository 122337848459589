import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Box, Layer, Text} from 'grommet';
import { MailOption } from 'grommet-icons';

import { AWS, GoogleTagManager, Spinner } from '../helper';

class ContactButton extends Component {
	
	state = {
		showPopup: false,
		recipients: [],
		gitSystem: '',
		cardType: '',
		url: '',
		loading: false
	}

	mailContact = async () => {
		GoogleTagManager.event('Contact', `${this.kind}_${this.category}`, this.url);
		
		this.setState({ loading: true });
		try { 
			this.setState(await AWS.getMails(this.props.data, this.props.category, this.props.kind));
		} catch (error) {
			console.log(error);
			this.setState({ recipients: [] });
		} finally {
			this.setState({ loading: false });
		}
		
		if(this.state.recipients.length > 0)
			this.setState({ showPopup: true });
		else
			this.props.errorLayer.current.setError('No contact could be identified. Feel free reach out to us directly for help!', true);
	}

	render(){
		const { showPopup, recipients, gitSystem, cardType, url, loading } = this.state;

		return (
			<Box>
				<Button style={{ margin: '0px', padding: '4px'}} margin="small" pad="small" size="small" color='red' icon={loading ? <Spinner /> : <MailOption />}  onClick={() => this.mailContact()} data-testid="contactButton" />
				{showPopup && (
					<Layer pad="medium" gap="large" width="auto" align="center"
						onEsc={() => this.setState({ showPopup: false })}
						onClickOutside={() => this.setState({ showPopup: false })}
					>
						<Box pad="medium" gap="large" width="auto" align="center">
							<Box gap="medium">
								{recipients.map((item, index) => (
									<Box direction="row" key={index} gap="small" margin="small" padding="small">
										<Button 
											style={{ margin: '0px', padding: '0px'}}
											margin="small" 
											size="small" 
											color='red' 
											icon={<MailOption />} 
											href={`mailto:${item.email}?subject=Question: ${cardType} in ${gitSystem}&body=${escape('Hello,\n\nCan you help me regarding')} ${url}`} 
										/>
										<Text>{item.name}</Text>
									</Box>
								))} 
							</Box>
							<Button direction="row" alignSelf="end"
								label={<Text color="white"><strong>OK</strong></Text>}
								onClick={() => this.setState({ showPopup: false })}
								primary
								color="accent-4"
							/>
						</Box>
					</Layer>
				)}
			</Box>
		);
	}
}

ContactButton.propTypes = {
	category: PropTypes.string,
	kind: PropTypes.string,
	data: PropTypes.object,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { ContactButton };