import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Box, Heading, Text } from 'grommet';

import { GenericCard } from './GenericCard';

class UserCard extends GenericCard {

	state = {
		name: '',
		username: '',
		web_url: '',
		avatar_url: '',
	}
	
	constructor(props) {
		super(props);

		this.state = this.loadData();
	}

	loadData() {
		const { kind, data } = this.props;

		let result;
		if(kind === 'github')
			result = { 
				isFeatured: false,
				name: data.login,
				username: '',
				web_url: data.html_url,
				avatar_url: '' //data.avatar_url 
			};
		else
			result = { 
				isFeatured: false,
				name: data.name,
				username: data.username,
				web_url: data.web_url,
				avatar_url: data.avatar_url == null ? '' : data.avatar_url
			};
		return result;
	}

	getTitle() {
		const { name, web_url } = this.state;
		return (
			<a style={{ textDecoration:'none'}} href={web_url} target="_blank" rel="noreferrer">
				<Box width="auto" flex={true} justify="between" direction="column">
					<a style={{ textDecoration:'none'}} href={web_url} target="_blank" rel="noreferrer">
						<Heading margin="xsmall" level="5" color="dark-1" fill>{name}</Heading>
					</a>
				</Box>
			</a>
			
		);
	}
	
	renderCardContent(){
		const { web_url, avatar_url, username } = this.state;
		const { kind } = this.props;
		return (
			<Box direction="row" pad="small" gap="medium" align="center">
				<Box  width="auto">
					<a style={{ textDecoration:'none'}} href={web_url} target="_blank" rel="noreferrer">
						<Avatar src={avatar_url == '' ? `${kind}.png` : avatar_url} size="xlarge" margin="small"/>
					</a>
				</Box>
				<Text>{ username != '' ? `@${username}` : 'No description available' }</Text>
			</Box>
		);
	}
}

UserCard.propTypes = {
	category: PropTypes.string,
	kind: PropTypes.string,
	data: PropTypes.object,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { UserCard };