import React from 'react';
import { Box, Heading, Text } from 'grommet';
import PropTypes from 'prop-types';
import { GenericCard } from './GenericCard';

const baseUrls = {
	cloud: 'https://docs.cloud.bayer.com',
	edh: 'https://edh.docs.cloud.bayer.com',
	atscale: 'https://data-at-scale.docs.cloud.bayer.com'
};

class DocsCard extends GenericCard {

	state = {
		title: '',
		text: ''
	}

	constructor(props) {
		super(props);

		this.state = this.loadData();
	}

	loadData() {
		const { data } = this.props;
		return ({ 
			isFeatured: false,
			title: data.title,
			text: data.text
		});
	}

	getTitle() {
		const { title } = this.state;
		const { data, category } = this.props;
		return (
			<Box justify="between" direction='row' align="center">
				<Box direction='row' align="center">
					<a style={{ textDecoration:'none'}} href={`${baseUrls[category]}/${data.location}`} target="_blank" rel="noreferrer">
						<Heading size="medium" margin="xsmall" level="4" color="dark-1">{title}</Heading>
					</a>
				</Box>
			</Box>
		);
	}
	
	getMarkedText = (text) => {
		const parts = text.split('<codesearch_marked>');
		return (
			<Text>
				{parts[0]} 
				<Text color="dark-2" weight={800}>
					 {parts[1]}
				</Text>
				{parts[2]}
			</Text>
		);
	}
	
	renderCardContent(){
		const { text } = this.state;
		const { data } = this.props;
		return (
			<Box width="auto" flex={true} pad={{horizontal: 'small', bottom: 'small'}}>
				{data.paragraphs.length > 0 && 
					data.paragraphs.map((item, index) => (
						<Box direction="row" key={index} gap="small" margin="small" padding="small">
							<a style={{ textDecoration:'none'}} href={`${baseUrls[this.props.category]}/${item.location}`} target="_blank" rel="noreferrer">
								<Text size="medium" margin="xsmall" level="4" color="dark-1" weight={600}>{item.title}</Text><br/>
								<Box margin={{left: 'large'}}>
									<Text size="small" margin={{top: 'small'}} color="dark-1">{this.getMarkedText(item.text)}</Text>
								</Box>
							</a>
						</Box>
					))
				}
				{data.paragraphs.length == 0 &&
					<Text size="small" margin={{top: 'small'}} color="dark-1">{this.getMarkedText(text)}</Text>
				}
			</Box>
		);
	}
}

DocsCard.propTypes = {
	category: PropTypes.string,
	kind: PropTypes.string,
	data: PropTypes.object,
	search: PropTypes.string,
	token: PropTypes.string,
	errorLayer: PropTypes.object
};

export { DocsCard };