import { Amplify, API, Hub, Auth } from 'aws-amplify';

import { capitalizeFirstLetter } from '../helper';

import config from '../config';

class AWS {

	static token = '';

	static retrieveToken = () => {
		return Auth.currentSession()
			.then(session => session)
			.catch(err => console.error('Login Error:', err));
	};

	static handleLogout = () => {
		Auth.signOut();
	};

	static call = (api, path, payload) => {
		return API.post(api, path, {
			body: payload,
			headers: {
				Authorization: `Bearer ${this.token}`
			}
		});
	}

	static initAmplify = () => {
		Amplify.configure({
			Auth: {
				region: config.cognito.REGION,
				userPoolId: config.cognito.USER_POOL_ID,
				userPoolWebClientId: config.cognito.APP_CLIENT_ID,
				oauth: {
					domain: config.cognito.OAUTH.DOMAIN,
					scope: config.cognito.OAUTH.SCOPE,
					redirectSignIn: config.cognito.OAUTH.REDIRECT_SIGNIN,
					redirectSignOut: config.cognito.OAUTH.REDIRECT_SIGNOUT,
					responseType: config.cognito.OAUTH.RESPONSE_TYPE
				}
			},
			API: {
				endpoints: [
					{
						name: 'search',
						endpoint: config.apiGateway.URL,
						region: config.apiGateway.REGION
					}
				],
			},
		});
	}

	static initHub = (setToken) => {
		Hub.listen('auth', ({payload: {event, data}}) => {
			switch (event) {
			case 'signIn':
			case 'cognitoHostedUI':
				this.retrieveToken().then(userToken => {
					this.token = userToken.idToken.jwtToken;
					setToken(this.token);
					localStorage.setItem('last_login', new Date(Date.now()).toISOString());
				});
				break;
			case 'signOut':
				break;
			case 'signIn_failure':
			case 'cognitoHostedUI_failure':
				console.log('Sign in failure', data);
				break;
			default:
				break;
			}
		});
	}

	// Check if user has recently signed in. Refresh token if necessary
	static initAuth = (setToken) => {
		Auth.currentSession()
			.then(data => {
				this.token = data.idToken.jwtToken;
				setToken(this.token);
			})
			.catch(err => {
				console.error('Login Error:', err);
				// Redirect to cognito / azure ad login if no token is present 
				if(window.location.pathname == '/' && (this.token == undefined || this.token == ''))
					window.location.href = `https://${config.cognito.OAUTH.DOMAIN}/oauth2/authorize?identity_provider=bayer-azuread&redirect_uri=${config.cognito.OAUTH.REDIRECT_SIGNIN}&response_type=TOKEN&client_id=${config.cognito.APP_CLIENT_ID}&state=ySyhKcGtQ4Ec1pi86vrRrSFijsf4TXgo&scope=email%20openid%20profile`;
			});
	}

	static getMails = async (data, category, kind) => {
		if(kind === 'github') {
			var payload_github = { };
			switch(category){
			case 'users': payload_github.cwid = data.login; break;
			case 'projects': payload_github.full_name = data.full_name; break;
			case 'issues':
			case 'requests': payload_github.cwid = data.user.login; break;
			case 'code': payload_github.full_name = data.repository.full_name; break;
			}
			return { 
				gitSystem: 'GitHub',
				cardType: category === 'request' ? 'Pull Request' : capitalizeFirstLetter(category),
				recipients: await this.call('search', '/contact', { 
					system: 'github',
					category: category,
					data: payload_github
				})
			};
		} else {
			var payload_gitlab = { };
			switch(category){
			case 'users':
			case 'projects': payload_gitlab.id = data.id; break;
			case 'issues':
			case 'requests': payload_gitlab.id = data.author.id; break;
			case 'code': payload_gitlab.id = data.project_id; break;
			}
			return { 
				gitSystem: 'GitLab',
				cardType: category === 'request' ? 'Merge Request' : capitalizeFirstLetter(category),
				recipients: await this.call('search', '/contact', { 
					system: 'gitlab',
					category: category,
					data: payload_gitlab
				})
			};
		}
	}

}

export { AWS };