import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Grommet, Grid, Box, ResponsiveContext } from 'grommet';

import { ErrorLayer, PageFooter, PageHeader } from './pagelayout';
import { App, Login, OpenPage, Privacy } from '../pages';

import { AWS, GoogleTagManager } from '../helper';
import { theme } from '../helper/theme';


class PageWrapper extends Component{

	state = {
		token: '',
		themeLive: theme
	}
	
	constructor(props){
		super(props);

		this.errorLayer = React.createRef();

		
		const themeUpdated = theme;
		themeUpdated.defaultMode = localStorage.getItem('appearance_mode') == undefined ? 'light' : localStorage.getItem('appearance_mode');
		this.themeLive = themeUpdated;

		GoogleTagManager.initialize();
	}

	componentDidMount(){
		AWS.initAmplify();
		AWS.initHub(this.setToken);
		AWS.initAuth(this.setToken);
	}

	setAppearanceMode = () => {
		this.setState({
			token: this.state.token,
			themeLive: this.state.themeLive
		});
	}

	setToken = (token) => {
		this.setState({
			token: token,
			themeLive: this.state.themeLive
		});
	}

	render(){
		const { themeLive, token } = this.state;
		return (
			<Grommet theme={themeLive} full>
				<Router>
					{/* Reponsive to adjust for desktop/mobile layout */}
					<ResponsiveContext.Consumer>
						{size => (
							<Grid
								fill
								rows={['60px', 'auto']}
								columns={['auto']}
								gap="small"
								style={{display: (token === '' ? 'none' : 'grid')}}
								areas={[
									{ name: 'header', start: [0, 0], end: [0, 0] },
									{ name: 'main', start: [0,1], end: [0,1] }
								]}
							>
								<PageHeader setAppearanceMode={this.setAppearanceMode} />
								
								{/* Main content switcher */}
								<Box gridarea="main" align="center">
									<Box 
										flex="grow"
										style={{maxWidth: '1240px', height: 'auto'}}
										margin={{bottom: 'medium'}}
										width="100%" 
									>
										<Routes>
											<Route path="/oauth/:type"
												element={<Login />}
											/>
											<Route path="/privacy"
												element={<Privacy size={size} />}
											/>
											<Route path="/open" 
												element={<OpenPage token={token} errorLayer={this.errorLayer} />}
											/>
											<Route path="/"
												element={<App size={size} token={token} errorLayer={this.errorLayer}/>}
											/>
										</Routes>

									</Box>

									<PageFooter size={size} />
								</Box>
							</Grid>
						)}
					</ResponsiveContext.Consumer>

					<ErrorLayer ref={this.errorLayer} />
				</Router>
			</Grommet>
		);	
	}
}

export { PageWrapper };
