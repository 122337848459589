/** available scopes
 * codesearch: "projects", "issues", "requests", "users", "code"
 * github: ["repositories", "issues", "pull_requests", "users", "code"]
 * gitlab: ["projects", "issues", "merge_requests", "users"]
 *
 * Function to build the correct scopes for CodeSearch API
 * Also returns the index of the first active tab
 * Returns an object with github and gitlab scopes and the first tab index.
 */
export const buildRequests = (controlledValues) => {
	const labScopes = [];
	const hubScopes = [];
	const docsScopes = [];
	let tempTabIndex = -1;
	for (let term in controlledValues){
		switch (controlledValues[term]) {
		case 'projects':
			tempTabIndex = 0;
			labScopes.push('projects');
			hubScopes.push('repositories');
			break;
		case 'issues':
			if (tempTabIndex === -1 || tempTabIndex > 1){
				tempTabIndex = 1;
			}
			labScopes.push('issues');
			hubScopes.push('issues');
			break;
		case 'requests':
			if (tempTabIndex === -1 || tempTabIndex > 2){
				tempTabIndex = 2;
			}
			labScopes.push('merge_requests');
			hubScopes.push('pull_requests');
			break;
		case 'users':
			if (tempTabIndex === -1 || tempTabIndex > 3){
				tempTabIndex = 3;
			}
			labScopes.push('users');
			hubScopes.push('users');
			break;
		case 'code': 
			if (tempTabIndex === -1 || tempTabIndex > 4){
				tempTabIndex = 4;
			}
			labScopes.push('blobs');
			hubScopes.push('code');
			break;
		case 'cloud':
			if (tempTabIndex === -1 || tempTabIndex > 5){
				tempTabIndex = 5;
			}
			docsScopes.push('cloud');
			break;
		case 'edh':
			if (tempTabIndex === -1 || tempTabIndex > 6){
				tempTabIndex = 6;
			}
			docsScopes.push('edh');
			break;
		case 'atscale':
			if (tempTabIndex === -1 || tempTabIndex > 7){
				tempTabIndex = 7;
			}
			docsScopes.push('atscale');
			break;
		default:
			break;
		}
	}
	return {
		labScopes: labScopes,
		hubScopes: hubScopes,
		docsScopes: docsScopes,
		tabIndex: tempTabIndex
	};
};

export const getSpecificCategory = (kind, category) => {
	let res = category;
	if(kind == 'gitlab'){
		if(category === 'requests') res = 'merge_requests';
		if(category === 'code') res = 'blobs';
	} else if(kind === 'github'){
		if(category === 'requests') res = 'pull_requests';
		if(category === 'projects') res = 'repositories';
	}
	return res;
};

/*
 * Function to turn a Date string into the easier readable 
 * "added x months ago" format.
 * Returns a string.
 */
export const updatedAgoCustom = (inputDate, dateTo) => {
	const dateFrom = new Date(inputDate);
	const diff = Math.round((dateTo-dateFrom)/(1000*60));
  
	if (diff<60){
		return `${diff} minute${(diff > 1) ? 's' : ''} ago`;
	} else if(diff<(60*24)) {
		const hours = Math.round(diff/60);
		return `${hours} hour${(hours > 1) ? 's' : ''} ago`;
	} else if (diff<(60*24*31)) {
		const days = Math.round(diff/(60*24));
		return `${days} day${(days > 1) ? 's' : ''} ago`;
	} else if (diff<(60*24*356)) {
		const months = Math.round(diff/(60*24*30));
		return `${months} month${(months > 1) ? 's' : ''} ago`;
	} else if (diff>=(60*24*356)) {
		const years = Math.round(diff/(60*24*30*12));
		return `${years} year${(years > 1) ? 's' : ''} ago`;
	}
};

export const updatedAgo = (inputDate) => {
	return  updatedAgoCustom(inputDate, new Date(Date.now()));
};

/*
 * Function to limit a paragraph and add three dots at the end
 * Returns a string.
 */
export const limitChars = (text, limit) => {
	let description = '';
	if (text) {
		if (text.length > limit) {
			description = `${text.slice(0, limit)}...`;
		} else {
			description = text;
		}
	}
	return description;
};

export const capitalizeFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getParams = (q) => {
	var paramPairs = q.substring(1).split('&');
	var params = {};
	for (var p in paramPairs){
		var pair = paramPairs[p].split('=');
		params[pair[0]] = pair[1];
	}
	return params;
};

export const buildRedirect = (response, params) => {
	return `${response.web_url}/-/tree/master/${params.filename}#L${params.startline}`;
};